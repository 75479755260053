<template>
    <div id="pageTable"> 
        <v-overlay :value="loadingLottie"  :z-index="999" >
            <div class="align-center justify-center text-center">
                <v-progress-circular
                    indeterminate
                    :size="100"
                    :width="7"
                    color="#2DB9D1"
                >
                    <v-img
                        src="/static/icon/favicon.ico"
                        :width="60"
                    ></v-img>
                </v-progress-circular>
                <p class="mt-5">{{fraseLoading}}</p>
            </div>
        </v-overlay>     
        <v-container xl fluid>
            <v-row>
                <div style="display: flex; width:100%; justify-content: flex-start;">
                    <hr class="vr"/>
                    <p class="tblName pb-2">Pólizas contables</p>
                </div>
                <v-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                    <v-card style="border-radius: 20px">
                        <ValidationObserver ref="form" v-slot="{ invalid }">
                            <v-card-text class="px-30px pt-30px">
                                <v-row class="pa-0">
                                    <template v-if="rol == 'root'">
                                        <v-col cols="12" xs="12" sm="12" md="12" class="pb-0">
                                            <ValidationProvider v-slot="{ errors }" name="Cliente" rules="required">
                                                <v-autocomplete
                                                    outlined
                                                    label="Cliente"
                                                    :items="clientes"
                                                    item-text="nombre"
                                                    item-value="id"
                                                    persistent-hint
                                                    v-model="cliente_value"
                                                    class="pa-0 ma-0"
                                                    required 
                                                    :error-messages="errors" 
                                                    no-data-text="Datos no disponibles"
                                                >
                                                </v-autocomplete>
                                            </ValidationProvider>
                                        </v-col>
                                    </template>
                                    <v-col cols="12" xs="12" sm="12" md="12" :class="rol=='root'?'py-0':'pb-0'">
                                        <ValidationProvider v-slot="{ errors }" name="Registro patronal" rules="required">
                                            <v-autocomplete
                                                outlined
                                                label="Registro patronal"
                                                :items="itemsRegistrosPatronales"
                                                item-text="nombreClave"
                                                item-value="id"
                                                persistent-hint
                                                v-model="registroPatronalModel"
                                                required 
                                                :error-messages="errors" 
                                                no-data-text="Datos no disponibles"
                                            >
                                            </v-autocomplete>
                                        </ValidationProvider>
                                    </v-col>

                                    <v-col cols="12" xs="12" sm="12" md="12" v-if="rol == 'root' || rol == 'admin'" class="py-0">
                                        <ValidationProvider v-slot="{ errors }" name="Empresa" rules="required">
                                            <v-autocomplete
                                                :items="itemEmpresas"
                                                item-text="nombre"
                                                item-value="id"
                                                label="Empresa"
                                                v-model="empresa_value"
                                                outlined
                                                class="ma-0 pa-0"
                                                required 
                                                :error-messages="errors"
                                                no-data-text="Datos no disponibles"
                                            >
                                            </v-autocomplete>
                                        </ValidationProvider>
                                    </v-col>

                                    <v-col cols="12" xs="12" sm="12" md="12" class="py-0">
                                        <ValidationProvider v-slot="{ errors }" name="Tipo de nómina" rules="required">
                                            <v-autocomplete
                                                outlined
                                                label="Tipo de nómina"
                                                :items="itemsTiposNominas"
                                                item-text="claveDescripcion"
                                                item-value="id"
                                                persistent-hint
                                                v-model="tipo_nomina_id"
                                                class="pa-0 ma-0"
                                                required 
                                                :error-messages="errors"
                                                no-data-text="Datos no disponibles"
                                            >
                                            </v-autocomplete>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col cols="12" xs="12" sm="12" md="12" class="py-0">
                                        <ValidationProvider v-slot="{ errors }" name="Periodo" rules="required">
                                            <v-autocomplete
                                                outlined
                                                label="Nómina"
                                                :items="itemsNominas"
                                                item-text="periodoFecha"
                                                item-value="id"
                                                persistent-hint
                                                v-model="nomina_id"
                                                class="pa-0 ma-0"
                                                required 
                                                :error-messages="errors"
                                                no-data-text="Datos no disponibles"
                                            >
                                            </v-autocomplete>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col cols="12" xs="12" sm="12" md="12" class="py-0">
                                        <VuetifyDatePicker
                                            outlined
                                            rules="required"
                                            v-model="fecha"
                                            label="Fecha"
                                            placeholder="dd/mm/aaaa"
                                            v-bind:showAppendOuter="true"
                                            v-bind:textTooltip="'El valor ingresado, será la fecha de captura en <br>el sistema contable.'"
                                            ref="Fecha"
                                        />
                                    </v-col>
                                    <v-col cols="12" xs="12" sm="12" md="12" class="py-0">
                                        <ValidationProvider v-slot="{ errors }" name="Tipo de póliza" rules="required">
                                            <v-autocomplete
                                                outlined
                                                label="Tipo de póliza"
                                                :items="itemsTiposPolizas"
                                                item-text="nombre"
                                                item-value="id"
                                                persistent-hint
                                                v-model="tipo_poliza_id"
                                                class="pa-0 ma-0"
                                                required 
                                                :error-messages="errors"
                                                no-data-text="Datos no disponibles"
                                            >
                                            </v-autocomplete>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col cols="12" xs="12" sm="12" md="12" class="py-0">
                                        <ValidationProvider v-slot="{ errors }" name="Folio" rules="required">
                                            <v-text-field 
                                                outlined 
                                                label="Número de póliza" 
                                                class="pa-0 ma-0"
                                                v-model="folio" 
                                                v-mask="'#########################'"
                                                :error-messages="errors" 
                                                required
                                            >
                                            </v-text-field>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col cols="12" xs="12" sm="12" md="12" class="py-0">
                                        <ValidationProvider v-slot="{ errors }" name="Concepto" rules="required">
                                            <v-text-field 
                                                outlined 
                                                label="Concepto" 
                                                class="pa-0 ma-0" 
                                                v-model="concepto" 
                                                :error-messages="errors" 
                                                required
                                            >
                                            </v-text-field>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="pa-0 d-flex">
                                        <v-container fluid class="py-0 d-flex">
                                            <v-checkbox class="pa-0 ma-0 checkboxVuetify" v-model="incluir_referencia" label="Incluir referencia"></v-checkbox>
                                        </v-container>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                            <v-card-actions class="d-flex justify-end pa-30px">
                                <v-btn
                                    class="btnGuardar"
                                    @click="consultar()"
                                    :loading="isLoadingConsultar"
                                    :disabled="invalid" 
                                >
                                    Consultar
                                </v-btn>
                            </v-card-actions>
                        </ValidationObserver>
                    </v-card>
                </v-col>
                <v-col cols="12" xs="12" sm="8" md="8" lg="8" xl="8">
                    <v-card style="border-radius: 20px">
                        <v-overlay :z-index="999" :value="overlayCargar" :absolute="true">
                            <div class="align-center justify-center text-center">
                                <v-progress-circular
                                    indeterminate
                                    :size="100"
                                    :width="7"
                                    color="#2DB9D1"
                                >
                                    <v-img
                                        src="/static/icon/favicon.ico"
                                        :width="60"
                                    ></v-img>
                                </v-progress-circular>
                                <p class="mt-5">Cargando...</p>
                            </div>
                        </v-overlay>
                        <v-card-text class="px-30px pt-30px pb-0">
                            <v-row class="pa-0 ma-0 informacionGeneral">
                                <v-row class="px-9 pt-12 pb-7">
                                    <v-col cols="12" xs="12" sm="12" md="12"  class="py-0">
                                        <p class="informacionGeneralTitulo">Información de la póliza</p>
                                    </v-col> 

                                    <v-col cols="12" xs="12" sm="4" md="4" class="py-0" :style="$vuetify.breakpoint.name != 'xs'? 'border-right:1px solid #EFF1FB;':''">
                                        <p class="mb-0 informacionGeneralSubtitle">Fecha:</p>
                                        <p class="informacionGeneralText">{{polizaFecha ? dateFormat(polizaFecha):"Información faltante*"}}</p>
                                    </v-col>
                                    <v-col cols="12" xs="12" sm="4" md="4" class="py-0" :style="$vuetify.breakpoint.name != 'xs'? 'border-right:1px solid #EFF1FB;':''">
                                        <p class="mb-0 informacionGeneralSubtitle">Tipo de póliza:</p>
                                        <p class="informacionGeneralText">{{polizaTipo ? polizaTipo:"Información faltante*"}}</p>
                                    </v-col>
                                    <v-col cols="12" xs="12" sm="4" md="4" class="py-0">
                                        <p class="mb-0 informacionGeneralSubtitle">Número de póliza:</p>
                                        <p class="informacionGeneralText">{{polizaFolio ? polizaFolio:"Información faltante*"}}</p>
                                    </v-col>

                                    <v-col cols="12" xs="12" sm="8" md="8" class="py-0" :style="$vuetify.breakpoint.name != 'xs'? 'border-right:1px solid #EFF1FB;':''">
                                        <p class="mb-0 informacionGeneralSubtitle">Concepto:</p>
                                        <p class="informacionGeneralText">{{polizaConcepto ? polizaConcepto:"Información faltante*"}}</p>
                                    </v-col>
                                    <v-col cols="12" xs="12" sm="4" md="4" class="py-0">
                                        <p class="mb-0 informacionGeneralSubtitle">Periodo de pago:</p>
                                        <p class="informacionGeneralText">{{periodoNomina ? periodoNomina:"Información faltante*"}}</p>
                                    </v-col>

                                    <v-col cols="12" xs="12" sm="4" md="4" class="py-0" :style="$vuetify.breakpoint.name != 'xs'? 'border-right:1px solid #EFF1FB;':''">
                                        <p class="mb-0 informacionGeneralSubtitle">Cargo:</p>
                                        <p class="informacionGeneralText">{{'$'+cargos}}</p>
                                    </v-col>
                                    <v-col cols="12" xs="12" sm="4" md="4" class="py-0" :style="$vuetify.breakpoint.name != 'xs'? 'border-right:1px solid #EFF1FB;':''">
                                        <p class="mb-0 informacionGeneralSubtitle">Abono:</p>
                                        <p class="informacionGeneralText">{{'$'+abonos}}</p>
                                    </v-col>
                                </v-row>
                            </v-row>
                        </v-card-text>
                        <v-card-text class="pa-30px" style="position:relative;">
                            
                            <v-row class="pa-0 ma-0 areaConsulta informacionGeneral" style="">
                                <v-container>
                                    <v-col 
                                        cols="12" xs="12" sm="12" md="12" 
                                        class="pa-0 " 
                                        :class="showDatos ? '':'d-flex justify-center align-center text-center'"
                                        :style="showDatos ? `background: #FFF; min-height:500px;`:`background: #F7F7F7; min-height:500px; `"
                                        
                                    >
                                        <v-btn
                                            color="#FFF"
                                            class="elevation-0 mr-13 mt-13 pt-0 pb-0 pl-0 pr-0"
                                            absolute
                                            top
                                            right
                                            reporte
                                            @click="reporte()"
                                            height="52"
                                            width="20"
                                            v-if="showBtnTxt"
                                            style="border-radius: 15px; box-shadow: 0px 5px 12px rgba(0, 0, 0, 0.08);"
                                        >
                                            <img src="/static/icon/txt.png" style="width:30px"/>
                                        </v-btn>
                                        <p v-if="!showDatos">No hay elementos para mostrar</p>
                                        <div v-else>
                                            <table class="tbl-modal" >
                                                <thead style="border-top-left-radius: 20px !important; border-top-right-radius: 20px !important;" class="tbl-header">
                                                    <tr style="border-top-left-radius: 20px; border-top-right-radius: 20px;" class="modaltr">
                                                        <th style="border-top-left-radius: 20px;" class="pl-7 modalth">Número</th>
                                                        <th class="modalth">Cuenta</th>
                                                        <th class="modalth">Nombre</th>
                                                        <th class="modalth">Cargo</th>
                                                        <th class="modalth">Abono</th>
                                                    </tr>
                                                </thead>
                                                <tbody id="tablaCuentaBody">
                                                    <tr v-if="informacionPolizaContble.data.length == 0" class="modaltr">
                                                        <td class="emptyTableModal" colspan="4">
                                                            No hay elementos para mostrar
                                                        </td>
                                                    </tr>
                                                    <template v-else v-for="(complemento, index) in informacionPolizaContble.data">
                                                        <template>
                                                            <tr :key="index" class="modaltr">
                                                                <td class="pl-7 modaltd">
                                                                    <div>{{index+1}}</div>
                                                                </td>
                                                                <td class="modaltd"> 
                                                                    <div>{{complemento.cuenta}}</div>
                                                                </td>
                                                                <td class="modaltd"> 
                                                                    <div>{{complemento.concepto}}</div> 
                                                                </td>
                                                                <td class="modaltd"> 
                                                                    <div v-if="complemento.tipo==0">
                                                                        {{formatNum(complemento.total)}}
                                                                    </div> 
                                                                </td>
                                                                <td class="modaltd">
                                                                    <div v-if="complemento.tipo==1">
                                                                        {{formatNum(complemento.total)}}
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </template>
                                                    </template>
                                                </tbody>
                                            </table>
                                        </div>
                                        
                                    </v-col>
                                </v-container>
                               
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import Vue from 'vue';
import apiClientes from "@/api/clientes";
import apiEmpresas from "@/api/empresas";
import apiRegistroPatronal from "@/api/nominas/registroPatronal";
import apiTipoNomina from '@/api/nominas/tipoNomina';
import apiPeriodos from '@/api/nominas/periodos';
import apiNomina from '@/api/nominas/calculoNomina';
import apiPolizaContable from '@/api/nominas/polizaContable';
import VuetifyDatePicker from '@/components/VuetifyDatePicker.vue';
import Notify from '@/plugins/notify';

export default {
    components: {
        VuetifyDatePicker,
    },
    data() {
        return {
            datosLogin                  : null,
            rol                         : null,
            clientes                    : [],
            cliente_value               : null,
            itemsRegistrosPatronales    : [],
            registroPatronalModel       : null,
            itemEmpresas                : [],
            empresa_value               : null,
            itemsTiposNominas           : [],
            tipo_nomina_id              : null,
            itemsNominas                : [],
            periodo_id                  : null,
            fecha                       : null,
            nomina_id                   : null,
            itemsTiposPolizas           : [
                {id:1,nombre:"Ingreso"}, {id:2, nombre:"Egreso"}, {id:3, nombre:"Diario"}
            ],
            tipo_poliza_id              : null,
            folio                       : null,
            concepto                    : null,
            informacionPolizaContble    : null,
            isLoadingConsultar          : false,
            showDatos                   : false,
            overlayCargar               : false,
            loadingLottie               : false,
            fraseLoading                : "",
            cargos                      : 0,
            abonos                      : 0,
            showBtnTxt                  : false,
            polizaFecha                 : null,
            polizaTipo                  : null,
            polizaConcepto              : null,
            polizaFolio                 : null,
            periodoNomina               : null,
            periodo                     : null,
            incluir_referencia          : false,
            datosPeriodo                :{
                clave_tipo_nomina   : '',
                numero_periodo      : '',
                fecha_final         : ''
            }
        }
    },
    watch: {
        cliente_value: function(val){
            this.resetDatos();
            if(val!= null){
                this.getEmpresas(val);
                this.getRegistroPatronal(val);
            }
        },
        registroPatronalModel:function(val){
            this.resetDatos();
        },
        empresa_value: function(val){
            this.resetDatos();
            if(val!=null){
                this.getTiposNominas();
            }
        },
        tipo_nomina_id:function(val){
            this.resetDatos();
            if(val!=null){
                this.getNominas(val);
            }
        },
        nomina_id:function(val){
            this.resetDatos();
            if(val!=null){
                this.getPeriodo(val);
            }
        },
        tipo_poliza_id:function(val){
            this.resetDatos();
        },
        folio:function(val){
            this.resetDatos();
        },
        concepto:function(val){
            this.resetDatos();
        },
        fecha:function(val){
            this.resetDatos();
        }
    },
    methods: {
        resetDatos(){
            this.showDatos = false;
            this.informacionPolizaContble = null;
            this.polizaFecha    = null;
            this.polizaTipo     = null;
            this.polizaConcepto = null;
            this.polizaFolio    = null;
            this.periodoNomina  = null;
            this.cargos         = 0;
            this.abonos         = 0;
            this.showBtnTxt     = false;
            this.polizaFecha    = null;
        },
        /**
         * @method getClientes Este método realiza petición a la API de clientes.
         * @description Realiza petición a la API de clientes cuando el rol es root y el resultado lo almacena en el array clientes[].
         * Si la petición no se realiza se manda un mensaje al usuario indicandole que no se pudo realizar la petición.
        */
        getClientes(cliente = null) {
            let param = { activo: true, paginate: false };
            apiClientes.getClientes(param).then((response) => {
                this.clientes = response.data;
            })
            .catch((error) => {
                console.error(err);
                Notify.ErrorToast("Ocurrió un error al cargar los clientes.");
            });
        },

        /**
         * @method getEmpresas Este método realiza petición a la API de empresas.
         * @description Realiza petición a la API de empresas cuando el cliente es asigando y el resultado lo almacena en el array itemEmpresas[].
         * Si la petición no se realiza se manda un mensaje al usuario indicandole que no se pudo realizar la petición.
        */
        async getEmpresas(cliente_id) {
            if(cliente_id == null){
                return;
            }

            let param = { activo: true, paginate: false, cliente_id: cliente_id };
            await apiEmpresas.getEmpresas(param).then(response => {
                this.itemEmpresas = response.data;
            })
            .catch(err => {
                    console.error(err);
                    Notify.ErrorToast("Ocurrió un error al cargar las empresas.");
            });
        },

        /**
         * @method getRegistroPatronal Este método realiza petición a la API de registros patronales.
         * @description Realiza petición a la API de registros patronales cuando el valor de cliente es asigando y el resultado lo almacena en el array itemsRegistrosPatronales[].
         * Si la petición no se realiza se manda un mensaje al usuario indicandole que no se pudo realizar la petición.
        */
        async getRegistroPatronal(){
            this.itemsRegistrosPatronales = [];
            let parametros = { activo:true, paginate:false, order:"asc", cliente_id: this.cliente_value }

            if(this.cliente_value == null){
                return;
            }
            await apiRegistroPatronal.find(parametros).then((response) => {
                let format = response.data;
                if(response.data.length != 0){
                    format.forEach( item => {
                        item.nombreClave = item.nombre + ' - ' + item.clave
                        this.itemsRegistrosPatronales.push(item);
                    })
                }
            })
            .catch(err => {
                console.log(err);
                Notify.ErrorToast("Ocurrió un error al cargar los Registros Patronales.");
            });
        },

        async getTiposNominas() {
            let param = {
                activo      : true,
                paginate    : false,
                cliente_id  : this.cliente_value,
                empresa_id  : this.empresa_value
            }
            this.itemsTiposNominas = [];
            await apiTipoNomina.find(param).then((response) => {
                let format = response.data;
                if(format.length != 0){
                    format.forEach( item => {
                        item.claveDescripcion = item.clave + ' - ' + item.descripcion
                        this.itemsTiposNominas.push(item);
                    })
                }
            })
            .catch (err => {
                console.log(err);
                Notify.ErrorToast("No se pudieron cargar los tipos de nómina");
            })
        },
        
        async getPeriodo(nomina_id){
            if(nomina_id == null){
                return;
            }

            let nomina = await this.itemsNominas.find(elemento => elemento.id == nomina_id);
            this.datosPeriodo = nomina
            await apiPeriodos.get(nomina.periodo_id).then((response) => {
                if(response){
                    this.periodo = response;
                    this.concepto = response.descripcion_fecha;
                }
            })
            .catch (err => {
                console.log(err);
                Notify.ErrorToast("Ocurrión un error al cargar el periodo.");
            })
        },

        async getNominas(tipoNomID) {
            if(tipoNomID == null){
                return;
            }
            this.itemsNominas = [];
            let param = { 
                activo      : true,
                paginate    : false,
                tipo_nomina_id : tipoNomID,
                acumulado   : true,
                cerrado     : true,
            };
            await apiNomina.find(param).then(response => {
                if(response.data.length > 0){
                    //this.itemsNominas = response.data;
                    response.data.forEach( async item => {
                        item.periodoFecha = item.numero_periodo + ' - ' + await this.dateFormat(item.fecha_final)
                        this.itemsNominas.push(item);
                    })
                }
            })
            .catch(err => {
                    console.error(err);
                    Notify.ErrorToast("Ocurrió un error al cargar las nóminas.");
            });
        },
        /**
         * @method formatNum Esta método parsea un número
         * @description Convierte un número a formato moneda, formato pesos mexicanos 
         * @param {number} num Valor númerico que puede tener números decimales.
         * @return {string} devuelve un string en formato $0.00
        */
        formatNum(num){
            const formatter = new Intl.NumberFormat('es-MX', {
                style: 'currency',
                currency: 'MXN',
            })
            return formatter.format(num);
        },

        /**
         * @method consultar Esta método realiza la petición para realizar las consultas de la póliza contable
         * @description Esta método realiza la petición para realizar las consultas de la póliza y lo muestra en el navegador.
        */
        consultar(){
            this.$refs.form.validate().then(success => {
                this.isLoadingConsultar = true;
                this.overlayCargar = true;
                if(!success) {
                    this.isLoadingConsultar = false;
                    return;
                }

                let parametros = {
                    nomina_id               : this.nomina_id,
                    registro_patronal_id    : this.registroPatronalModel,
                    tipo_poliza             : this.tipo_poliza_id,
                    folio                   : this.folio,
                    concepto                : this.concepto,
                    fecha                   : this.fecha, 
                    tipo_reporte            : 'json',
                    incluir_referencia      : this.incluir_referencia,
                }
                apiPolizaContable.generarPoliza(parametros).then((response) => {
                    if(response.general){
                        this.showBtnTxt = true;
                        this.cargos = response.general.cargos;
                        this.abonos = response.general.abonos;
                        this.polizaFecha = response.general.fecha;
                        this.informacionPolizaContble = response;

                        if(this.periodo){
                            this.periodoNomina = this.dateFormat(this.periodo.fecha_inicial) + ' - ' + this.dateFormat(this.periodo.fecha_final)
                        }

                        let tipoDePoliza = this.itemsTiposPolizas.find( elemento => elemento.id == this.tipo_poliza_id ) 
                        this.polizaTipo = tipoDePoliza.nombre;

                        this.polizaFolio =  this.folio;
                        this.polizaConcepto = this.concepto;
                        
                        this.showDatos = true;
                    }
                    this.isLoadingConsultar = false;
                    this.overlayCargar = false;
                    
                })
                .catch(err => {
                    this.isLoadingConsultar = false;
                    this.overlayCargar = false;
                    console.log(err);
                    if(typeof err.response.data.error === 'string'){
                        Notify.ErrorToast(err.response.data.error);
                    }else{
                        let error = Object.values(err.response.data.error);
                        let leyenda = ``;

                        for (var i = 0; i < error.length; i++) {
                            leyenda+= `* ` + error[i] + `\n`;
                        }

                        Notify.ErrorToast(leyenda);
                    }
                });
            });
        },
        reporte(tipo = 'txt'){
            let self = this;
            this.loadingLottie =  true;
            this.fraseLoading = "Descargando";
            const FileDownload = require("js-file-download");

            let parametros = { 
                nomina_id               : this.nomina_id,
                registro_patronal_id    : this.registroPatronalModel,
                tipo_poliza             : this.tipo_poliza_id,
                folio                   : this.folio,
                concepto                : this.concepto,
                fecha                   : this.fecha, 
                tipo_reporte            : 'txt',
                incluir_referencia      : this.incluir_referencia,
                
            }

            let nombre;
            //nombre = this.folio+"_poliza_contable."+tipo;
            nombre = "Poliza_contable_"+ this.datosPeriodo.clave_tipo_nomina + "_" + this.datosPeriodo.numero_periodo + "_" + this.formatoFecha(this.datosPeriodo.fecha_final) + "." + tipo

            let url = "calculo-nomina/generar-poliza";
            try {
                Vue.axios({
                    method: "POST",
                    responseType: "blob",
                    url: url,
                    data: parametros,
                }).then((response, status, xhr) => {
                    FileDownload(response.data, nombre);
                    self.loadingLottie = false;
                })
                .catch(err =>{
                    console.log(err);
                    self.loadingLottie = false;
                });
            } 
            catch (err) {
                Notify.ErrorToast("No se pudo realizar la petición.");
                console.log(err);
                self.loadingLottie = false;
            }
        },
        dateFormat(fecha, tipo) {
            if(fecha == null){
                return '';
            }
            let fecha2 = fecha.split("-");
            if(tipo == 'tabla'){
                return fecha2[2] + " / " + fecha2[1]+ " / " + fecha2[0];
            }
            else{
                return fecha2[2] + "/" + fecha2[1]+ "/" + fecha2[0];
            }
            
        },
        formatoFecha(fecha, tipo) {
            if(fecha == null){
                return '';
            }
            let fecha2 = fecha.split("-");
            if(tipo == 'tabla'){
                return fecha2[2] + " - " + fecha2[1]+ " - " + fecha2[0];
            }
            else{
                return fecha2[2] + "-" + fecha2[1]+ "-" + fecha2[0];
            }
            
        },
    },
    created() {
        this.datosLogin = this.$session.get("usuario");
        this.rol = this.datosLogin.rol.name;

        if (this.rol == "root") {
            this.getClientes();
        }
        else if(this.rol == "admin"){
            this.cliente_value = this.datosLogin.cliente_id;
        }
        else{
            this.cliente_value = this.datosLogin.cliente_id;
            this.empresa_value = this.datosLogin.empresa_id;
        }
        
    },    
}
</script>

<style>
    .informacionGeneral{
        border: 1px solid #C4C4C4;
        box-sizing: border-box;
        border-radius: 15px;
    }
    .informacionGeneralTitulo{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: #1E2144;
    }
    .informacionGeneralSubtitle{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: #828282;
    }
    .informacionGeneralText{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: #4F4F4F;
    }
    .areaConsulta{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: normal;
        font-size: 24px;
        line-height: 37px;
        color: #828282;
    }
    .btnClass{
        width: 200px;
        background-color: cornflowerblue;
        height: 60px;
        border: none;
        color: #ffffff;
        font-size:30px;
        cursor:pointer
    }
    
    .subtitle{
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        color: #828282;
        margin-bottom:0px;
    }
    .text{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 19px;
        color: #828282;
        margin-bottom:0px;
    }


    .emptyTableModal{
        padding-top: 50px !important;
        padding-bottom: 50px !important;
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        text-align: center !important;
        color: #c5c5c5;
    }
    .tbl-modal {
        border-collapse: collapse;
        width: 100%;
        margin-top: 15px;
        overflow-x: scroll;
    }

    .tbl-modal .tbl-header {
        background: transparent;
        height: 58px;
        min-width: 400px;
    }
    .modalth {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        color: #444975;
        background: #FFFFFF;
        line-height: 20px;
        padding: 8px;
        text-align: left;
        height: 58px;
    }
    .modaltd {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        color: #828282;
        line-height: 15px;
        padding: 8px;
        text-align: left !important;
    }
    .modaltr{
        display: table;
        table-layout: fixed;
        width: 100%;
    }
    #tablaCuenta table {
        border-collapse: collapse;
        width: 100%;
        overflow-x: scroll;
    }
    #tablaCuentaBody {
      display:block;
      max-height:50vh;
      min-width: 90%;
      background-color: #FFFFFF;
    }
</style>